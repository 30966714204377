export const FirebaseConfig = {
	"projectId": "berpindah-944ee",
	"appId": "1:674582554191:web:3ac267a92187ea501d2c3a",
	"databaseURL": "https://berpindah-944ee-default-rtdb.asia-southeast1.firebasedatabase.app",
	"storageBucket": "berpindah-944ee.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBh-lNbA3wdlSW42nQAmjzYffDPcL7lIMI",
	"authDomain": "berpindah-944ee.firebaseapp.com",
	"messagingSenderId": "674582554191",
	"measurementId": "G-L1FEW8GYXF"
};